import React, { useCallback, useEffect, useRef } from 'react';

import { COMMAND_IDS } from '@apple';
import { TAppleCommand } from '@capasystems/types';
import { Button, Checkbox, Column, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LayoutRow, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { isDefined, isFunction, noop } from '@capasystems/utils';
import { TAppleEndpoint, TAppleEndpointWithId } from '@db/party';
import { SchemaBuilder, useAppleDeviceCommandQueueSocket, useNavigate } from '@thirdparty/ui';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useAppleApi } from '../../../hooks/useApi/useApi';
import useMessageContext from '../../../hooks/useMessageContext/useMessageContext';
import { ActionsDialog, ActionsIconButton, RenamingDialog } from '../../../thirdparty-components/thirdparty-components';

type TAction = {
    id: string;
    name: string;
    icon?: string;
    tooltipProps?: {
        content: string;
        fullWidth: boolean;
    };
    disabled?: boolean;
    schema?: any;
    dataIsValid?: (data: any) => boolean;
};

const getListOfAvailableEndpointCommands = (isMac: boolean, endpoint: TAppleEndpoint | null) => {
    const isSupervised = endpoint?.data?.IsSupervised || false;
    const disabled = endpoint !== null && !isSupervised;
    return [
        {
            id: COMMAND_IDS.DEVICEINFO,
            name: 'Synchronize',
            icon: 'autoUpdate',
            tooltipProps: {
                content: endpoint === null ? 'Get the newest data for the selected endpoints' : 'Get the newest data for this device',
                fullWidth: true,
            },
        },
        {
            id: COMMAND_IDS.RESTART_DEVICE,
            name: 'Reboot',
            icon: 'reset',
            tooltipProps: {
                content: endpoint === null ? 'Reboot the selected endpoints' : isSupervised ? 'Reboot the endpoint' : 'Device needs to be supervised to reboot',
                fullWidth: true,
            },
            disabled,
            schema: {
                type: 'object',
                schemaId: 'restartEndpoint',
                properties: {
                    NotifyUser: {
                        title: 'Notify user',
                        description: "If you don't notify the user, the device will restart immediately",
                        type: 'boolean',
                        required: true,
                        defaultValue: true,
                    },
                },
            },
        },
        {
            id: COMMAND_IDS.DEVICE_LOCK,
            name: 'Lock',
            icon: 'lock',
            tooltipProps: {
                content: endpoint === null ? 'Lock the selected endpoints' : isSupervised ? 'Lock the endpoint' : 'Device needs to be supervised to lock',
                fullWidth: true,
            },
            disabled,
            schema: {
                type: 'object',
                schemaId: 'lockEndpoint',
                properties: {
                    message: {
                        title: 'Message',
                        description:
                            'The message to display on the Lock screen of the device. This value doesn’t apply to a shared iPad device. This value is available in iOS 4 and later, and macOS 10.14 and later',
                        type: 'string',
                    },
                    phone_number: {
                        title: 'Phone Number',
                        description:
                            'The phone number to display on the Lock screen. This value doesn’t apply to a shared iPad device. This value is available in iOS 7 and later and macOS 11.5 and later (for Apple silicon devices only)',
                        type: 'string',
                    },
                    pin: {
                        title: 'PIN',
                        description: 'The six-character PIN for Find My',
                        type: 'string',
                    },
                },
            },
        },
        {
            id: COMMAND_IDS.SHUTDOWN_DEVICE,
            name: 'Shut down',
            icon: 'power',
            tooltipProps: {
                content:
                    endpoint === null
                        ? 'Shut down the selected endpoints'
                        : isSupervised
                          ? 'Shut down the endpoint'
                          : 'Device needs to be supervised to shutdown',
                fullWidth: true,
            },
            disabled,
        },
        {
            id: COMMAND_IDS.ERASE_DEVICE,
            name: 'Wipe',
            icon: 'deleteSweepOutlined',
            tooltipProps: {
                content: endpoint === null ? 'Wipe the selected endpoints' : isSupervised ? 'Wipe the endpoint' : 'Device needs to be supervised to wipe',
                fullWidth: true,
            },
            disabled,
            schema: {
                type: 'object',
                schemaId: 'eraseDevice',
                properties: {
                    DisallowProximitySetup: {
                        title: 'Disallow Proximity Setup',
                        description:
                            'If true, disable Proximity Setup on the next reboot and skip the pane in Setup Assistant. This value is available in iOS 11 and later. Prior to iOS 14, don’t use this option with any other option',
                        type: 'boolean',
                    },
                    PreserveDataPlan: {
                        title: 'Preserve Data Plan',
                        description:
                            'If true, disable Proximity Setup on the next reboot and skip the pane in Setup Assistant. This value is available in iOS 11 and later. Prior to iOS 14, don’t use this option with any other option',
                        type: 'boolean',
                    },
                    PIN: {
                        title: 'PIN',
                        description: 'The six-character PIN for Find My',
                        type: 'string',
                    },
                },
            },
        },
        {
            id: COMMAND_IDS.SCHEDULE_OS_UPDATE,
            name: 'Update OS',
            icon: 'updateOS',
            tooltipProps: {
                content:
                    endpoint === null
                        ? 'Install OS update on the selected endpoints'
                        : isSupervised
                          ? 'Install OS update on the endpoint'
                          : 'Device needs to be supervised to install an OS update',
                fullWidth: true,
            },
            disabled,
            schema: {
                type: 'object',
                schemaId: 'ScheduleOSUpdate',
                properties: {
                    install_action: {
                        title: 'Install Action',
                        description: 'How to install the OS update. This value is available in iOS 9 and later, and macOS 10.11 and later',
                        type: 'string',
                        options: [
                            { id: 'Default', name: 'Download & Install' },
                            { id: 'DownloadOnly', name: 'Download only' },
                            { id: 'InstallASAP', name: 'Install Now' },
                            ...(isMac
                                ? [
                                      { id: 'NotifyOnly', name: 'Notify only' },
                                      { id: 'InstallLater', name: 'Install later' },
                                      { id: 'InstallForeceRestart', name: 'Install and force restart' },
                                  ]
                                : []),
                        ],
                        required: true,
                    },
                    max_user_deferrals: {
                        title: 'Max User Deferrals',
                        description:
                            'The maximum number of times the system allows the user to postpone an update before it’s installed. The system prompts the user once a day.',
                        type: 'number',
                        shouldRender: (siblings: any) => {
                            if (siblings.install_action === 'InstallLater') {
                                return true;
                            }
                            delete siblings.MaxUserDeferrals;
                            return false;
                        },
                    },
                    priority: {
                        title: 'Priority',
                        description:
                            'The scheduling priority for downloading and preparing the requested update. This is only supported for minor OS updates (macOS 12.x to 12.y).',
                        type: 'string',
                        options: [
                            { id: 'Low', name: 'Low' },
                            { id: 'High', name: 'High' },
                        ],
                    },
                },
            },
            dataIsValid: (data: any) => {
                return (data.install_action || '') !== '';
            },
        },
        {
            id: COMMAND_IDS.CLEAR_PASSCODE,
            name: 'Clear password',
            icon: 'key',
            tooltipProps: {
                content:
                    endpoint === null
                        ? 'Clear password on the selected endpoints'
                        : isSupervised
                          ? 'Clear password on the endpoint'
                          : 'Device needs to be supervised to clear the password',
                fullWidth: true,
            },
            disabled,
        },
        {
            id: COMMAND_IDS.ENABLE_LOST_MODE,
            name: 'Start lost mode',
            icon: 'search',
            tooltipProps: {
                content:
                    endpoint === null
                        ? 'Start lost mode on the selected endpoints'
                        : isSupervised
                          ? 'Start lost mode on the endpoint'
                          : 'Device needs to be supervised to start lost mode',
                fullWidth: true,
            },
            disabled,
            schema: {
                type: 'object',
                schemaId: 'enableLostMode',
                properties: {
                    Message: {
                        title: 'Message',
                        description:
                            'If present, display this text on the Lock screen. You must provide this value if you don’t provide a value for PhoneNumber',
                        type: 'string',
                    },
                    PhoneNumber: {
                        title: 'Phone Number',
                        description:
                            'If present, display this phone number on the Lock screen. You must provide this value if you don’t provide a value for Message',
                        type: 'string',
                    },
                    Footnote: {
                        title: 'Footnote',
                        description: 'If present, display this text in place of Slide to Unlock',
                        type: 'string',
                    },
                },
            },
        },
        {
            id: COMMAND_IDS.DISABLE_LOST_MODE,
            name: 'Stop lost mode',
            icon: 'searchOff',
            tooltipProps: {
                content:
                    endpoint === null
                        ? 'Stop lost mode on the selected endpoints'
                        : isSupervised
                          ? 'Stop lost mode on the endpoint'
                          : 'Device needs to be supervised to stop lost mode',
                fullWidth: true,
            },
            disabled,
        },
    ];
};

const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const viewRequestsAction = {
    id: 'viewRequests',
    name: 'View requests',
};

const deleteEndpointAction = {
    id: 'delete',
    name: 'Delete endpoint data',
    icon: 'delete',
    tooltipProps: {
        content: 'Delete the endpoint data from CapaOne',
        fullWidth: true,
    },
};

type TCoreAppleEndpointCommandsProps = {
    endpoint: TAppleEndpointWithId | null;
    filter: string | null;
    affectedEndpointsCount: number;
    isMac: boolean;
};

type TAppleEndpointCommandsProps = {
    endpoint: TAppleEndpoint;
};

type TAppleEndpointListCommandsProps = {
    filter: string;
    affectedEndpointsCount: number;
    isMac: boolean;
};

type TActionDialogProps = {
    open: boolean;
    anchorEl: any;
    category: string;
    title: string;
};

const AppleEndpointListCommands: React.FC<TAppleEndpointListCommandsProps> = ({ filter, affectedEndpointsCount, isMac }) => {
    return (
        <CoreAppleEndpointCommands
            filter={filter}
            affectedEndpointsCount={affectedEndpointsCount}
            endpoint={null}
            isMac={isMac}
        />
    );
};

const AppleEndpointCommands: React.FC<TAppleEndpointCommandsProps> = ({ endpoint }) => {
    return (
        <CoreAppleEndpointCommands
            endpoint={endpoint}
            filter={null}
            affectedEndpointsCount={1}
            isMac={endpoint.data?.ModelName?.toLowerCase().includes('mac') || false}
        />
    );
};

const CoreAppleEndpointCommands: React.FC<TCoreAppleEndpointCommandsProps> = ({ endpoint, filter, affectedEndpointsCount, isMac }) => {
    const appleApi = useAppleApi();
    const [actionDialogProps, setActionDialogProps] = useState<TActionDialogProps>({
        open: false,
        anchorEl: null,
        category: '',
        title: '',
    });
    const [selectedAction, setSelectedAction] = useState<TAction | null>(null);

    const [actions] = useState<any>(() => {
        if (endpoint) {
            return [
                {
                    id: 'requestAction',
                    name: 'Request',
                    actions: getListOfAvailableEndpointCommands(isMac, endpoint),
                },
                renameAction,
                viewRequestsAction,
                deleteEndpointAction,
            ];
        }
        return getListOfAvailableEndpointCommands(isMac, null);
    });

    const [isRenaming, setIsRenaming] = useState(false);
    const [viewRequests, setViewRequest] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        if (endpoint && endpoint._id) {
            appleApi
                .renameEndpoint(endpoint._id, {
                    name: newName,
                })
                .then(noop)
                .catch(() => {
                    setIsRenaming(true);
                });
            return;
        }
    };

    const openMenu = (e: React.MouseEvent) => {
        setActionDialogProps({
            open: true,
            anchorEl: e.currentTarget,
            category: endpoint ? 'Endpoint' : 'Available requests',
            title: endpoint?.name || `${pluralize('endpoint', affectedEndpointsCount, true)} affected`,
        });
    };

    const closeMenu = () => {
        setActionDialogProps((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action: TAction) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                setIsRenaming(true);
                break;
            case viewRequestsAction.id:
                setViewRequest(true);
                break;
            case deleteEndpointAction.id:
                setShowDeleteDialog(true);
                break;
            default:
                setSelectedAction(action);
        }
        return null;
    };

    return (
        <>
            <ActionsIconButton
                /** @ts-ignore */
                onClick={openMenu}
                noMargin
                isBulkAction={endpoint === null}
            />
            <ActionsDialog
                {...actionDialogProps}
                actions={actions}
                onClose={closeMenu}
                onActionClick={onActionClick}
                description=""
            />
            <SelectedActionDialog
                action={selectedAction}
                onClose={() => {
                    setSelectedAction(null);
                }}
                affectedEndpointsCount={affectedEndpointsCount}
                filter={filter}
                endpoint={endpoint}
            />
            {endpoint && (
                <>
                    <RenamingDialog
                        onCancel={() => setIsRenaming(false)}
                        onSubmit={onRenameSubmit}
                        currentName={endpoint.name ?? 'Unknown'}
                        open={isRenaming}
                    />
                    <ViewRequests
                        onClose={() => {
                            setViewRequest(false);
                        }}
                        open={viewRequests}
                        endpoint={endpoint}
                    />
                    <DeleteEndpointDataDialog
                        open={showDeleteDialog}
                        onClose={() => setShowDeleteDialog(false)}
                        endpoint={endpoint}
                    />
                </>
            )}
        </>
    );
};

type ViewRequestsDialogProps = { open: boolean; onClose: () => void; endpoint: TAppleEndpoint };

type selectedActionDialogProps = {
    action: TAction | null;
    onClose: () => void;
    affectedEndpointsCount: number;
    filter: string | null;
    endpoint: TAppleEndpoint | null;
};

const SelectedActionDialog: React.FC<selectedActionDialogProps> = ({ action, onClose, affectedEndpointsCount, filter, endpoint }) => {
    const appleApi = useAppleApi();
    const { handleApiError } = useMessageContext();
    const schemaBuilderRef = useRef({});
    const [disabled, setDisabled] = useState(true);
    const [accepted, setAccepted] = useState(endpoint !== null);
    const [isProcessing, setIsProcessing] = useState(false);

    if (action === null) {
        schemaBuilderRef.current = {};
        return null;
    }

    const onSubmit = () => {
        setIsProcessing(true);
        if (endpoint) {
            appleApi
                .sendEndpointCommand(endpoint.udid, { commandId: action.id, data: action.schema ? schemaBuilderRef.current : undefined })
                .then(onClose)
                .catch((error) => {
                    handleApiError(error);
                    setIsProcessing(false);
                });
        } else {
            appleApi
                .sendBulkCommand({ commandId: action.id, filter, data: action.schema ? schemaBuilderRef.current : undefined })
                .then(onClose)
                .catch((error) => {
                    handleApiError(error);
                    setIsProcessing(false);
                });
        }
    };

    return (
        <Dialog
            open={isDefined(action.id)}
            onClose={onClose}
            size="md"
            onEnter={() => {
                setAccepted(endpoint !== null);
                if (isFunction(action.dataIsValid)) {
                    setDisabled(!action.dataIsValid(schemaBuilderRef.current));
                } else {
                    setDisabled(false);
                }
                setIsProcessing(false);
            }}
            onExited={() => {
                schemaBuilderRef.current = {};
            }}
        >
            <DialogTitle className="tw-pb-0s tw-text-lg">
                {filter && (
                    <>
                        Send <span className="tw-underline tw-underline-offset-4">{action.name}</span> command to{' '}
                        {pluralize('endpoint', affectedEndpointsCount, true)}?
                    </>
                )}
                {endpoint && (
                    <>
                        Send <span className="tw-underline tw-underline-offset-4">{action.name}</span> command to {endpoint.name}?
                    </>
                )}

                <div className="tw-mt-1 tw-text-sm tw-text-gray-700">The command will be sent immediately. You can't undo this action.</div>
            </DialogTitle>

            {action.schema && (
                <>
                    <Divider light />
                    <SchemaBuilder
                        schema={action.schema}
                        currentState={schemaBuilderRef.current}
                        onRefChange={() => {
                            if (isFunction(action.dataIsValid)) {
                                setDisabled(!action.dataIsValid(schemaBuilderRef.current));
                            } else {
                                setDisabled(false);
                            }
                        }}
                    />
                    <Divider
                        className="tw-mb-1"
                        light
                    />
                </>
            )}
            <DialogActions className="tw-items-center tw-px-6">
                <div className="tw-flex-1">
                    <AcceptCheckbox
                        accepted={accepted}
                        setAccepted={setAccepted}
                    />
                </div>
                <Tooltip
                    content={
                        disabled
                            ? 'Please fill in the required fields'
                            : accepted
                              ? 'Please note, that only supervised devices will accept this command. Unsupervised devices will reject the command.'
                              : 'You must accept if you want to proceed'
                    }
                    disableHoverListener={endpoint !== null && !disabled && accepted}
                >
                    <div>
                        <Button
                            onClick={onSubmit}
                            color="primary"
                            variant="contained"
                            disabled={disabled || !accepted || isProcessing}
                        >
                            {action.name}
                        </Button>
                    </div>
                </Tooltip>
                <Button
                    onClick={onClose}
                    noMargin
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

type TAppleCommandWithTitle = TAppleCommand & { title: string };

const ViewRequests: React.FC<ViewRequestsDialogProps> = ({ open, onClose, endpoint }) => {
    const appleApi = useAppleApi();
    const [requestList, setRequestList] = useState<TAppleCommandWithTitle[]>([]);
    const [loading, setLoading] = useState(false);
    const getEndpointCommands = () => {
        setLoading(true);
        appleApi
            .getEndpointCommands(endpoint.udid)
            .then(getReadableName)
            .then((response) => {
                setRequestList(response);
            })
            .catch(noop)
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (open) {
            getEndpointCommands();
        } else {
            setRequestList([]);
        }
    }, [open]);

    const getReadableName = (data: TAppleCommand[]) => {
        const appIdentifierToDisplay = (requestType: TAppleCommand) => {
            let appIdentifier = '';
            if (requestType.data?.Identifier) {
                appIdentifier = requestType?.data?.Identifier;
            } else if (requestType.data?.iTunesStoreID) {
                appIdentifier = requestType?.data?.iTunesStoreID;
            } else {
                appIdentifier = 'with an unknown identifier';
            }
            return appIdentifier;
        };
        const readableRequestType = data?.map((requestType) => {
            const titleObject: Record<string, string> = {
                [COMMAND_IDS.SHUTDOWN_DEVICE]: 'Shutting down Device',
                [COMMAND_IDS.DEVICEINFO]: 'Gathering device information',
                [COMMAND_IDS.RESTART_DEVICE]: 'Restarting device',
                [COMMAND_IDS.DEVICE_LOCK]: 'Locking device',
                [COMMAND_IDS.INSTALLEDAPPLICATIONLIST]: 'Getting new software inventory ',
                [COMMAND_IDS.CLEAR_PASSCODE]: 'Clearing password',
                [COMMAND_IDS.ERASE_DEVICE]: 'Wiping device data',
                [COMMAND_IDS.PROFILELIST]: 'Updating applied configurations',
                [COMMAND_IDS.SECURITYINFO]: 'Gathering security information',
                [COMMAND_IDS.OS_UPDATE_STATUS]: 'Getting current OS status',
                [COMMAND_IDS.AVAILABLEOSUPDATES]: 'Checking for available OS updates',
                [COMMAND_IDS.SCHEDULE_OS_UPDATE]: 'Installing OS update',
                [COMMAND_IDS.RESTRICTIONS]: 'Updating device restrictions',
                [COMMAND_IDS.INSTALL_APPLICATION]: `Installing application ` + appIdentifierToDisplay(requestType),
                [COMMAND_IDS.REMOVE_APPLICATION]: `Uninstalling application ` + appIdentifierToDisplay(requestType),
                [COMMAND_IDS.INSTALL_PROFILE]: `Applying ${requestType.command_uuid?.split('_')[1] || 'unknown'} configuration`,
                [COMMAND_IDS.REMOVE_PROFILE]: `Removing ${requestType.command_uuid?.split('_')[1] || 'unknown'} configuration`,
                [COMMAND_IDS.SETTINGS]: 'Applying settings',
            };
            const title = titleObject[requestType.request_type] || 'Unknown request';

            return { ...requestType, title };
        });
        return readableRequestType;
    };

    useAppleDeviceCommandQueueSocket(
        endpoint.udid,
        useCallback(
            ({ fullDocument }: any) => {
                const getReadableRequestName = getReadableName(fullDocument.commands);
                setRequestList(getReadableRequestName);
            },
            [endpoint.udid],
        ),
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="lg"
        >
            <DialogTitle>
                <LayoutRow align="space-between">
                    <p>Commands requests for {endpoint.name}</p>
                    <Tooltip content="This will clear the queue and retry installation of configurations and applications">
                        <Button
                            onClick={() => {
                                appleApi.sendEndpointCommand(endpoint.udid, { commandId: COMMAND_IDS.CLEAR_COMMAND_QUEUE, data: undefined }).then(() => {
                                    getEndpointCommands();
                                });
                            }}
                            color="primary"
                            variant="contained"
                            noMargin
                        >
                            Clear Queue
                        </Button>
                    </Tooltip>
                </LayoutRow>
            </DialogTitle>
            <div className="tw-h-screen">
                <VirtualizedTable
                    showRowCount
                    totalRowCount={requestList?.length || 0}
                    isLoading={loading}
                    // @ts-ignore
                    items={requestList}
                >
                    <Column
                        key="date"
                        dataKey="date"
                        label="Date"
                        minWidth={120}
                        maxWidth={120}
                        type="timestamp"
                        cellRenderer={({ rowData }) => {
                            return rowData.timestamp ? dayjs(rowData?.timestamp).fromNow() : 'Never';
                        }}
                    />
                    <Column
                        key="title"
                        dataKey="title"
                        label="Request name"
                        minWidth={160}
                        type="string"
                    />
                    <Column
                        key="retries"
                        dataKey="retries"
                        label="Retries"
                        minWidth={80}
                        maxWidth={80}
                        type="number"
                    />
                </VirtualizedTable>
            </div>
        </Dialog>
    );
};

type deleteEndpointDataDialogProps = { open: boolean; onClose: () => void; endpoint: TAppleEndpoint };

const DeleteEndpointDataDialog: React.FC<deleteEndpointDataDialogProps> = ({ open, onClose, endpoint }) => {
    const appleApi = useAppleApi();
    const navigate = useNavigate();
    const { handleApiError } = useMessageContext();
    const [accepted, setAccepted] = useState(false);

    const onDeleteSubmit = () => {
        appleApi
            .sendEndpointCommand(endpoint.udid, { commandId: COMMAND_IDS.DELETE_ENDPOINT_DATA, data: undefined })
            .then(() => {
                navigate.to('apple/endpoint/list/mobile');
            })
            .catch(handleApiError);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="sm"
            onEnter={() => setAccepted(false)}
        >
            <DialogTitle>Delete Endpoint Data?</DialogTitle>
            <DialogContent className="tw-font-medium">
                <div>This will only delete the data and relation to the device from CapaOne. It will not send any command or wipe the device.</div>
                <div className="tw-mt-4">If you want to wipe the device then use Wipe request.</div>
            </DialogContent>
            <DialogActions className="tw-px-6">
                <div className="tw-flex-1">
                    <AcceptCheckbox
                        accepted={accepted}
                        setAccepted={setAccepted}
                    />
                </div>
                <Button
                    onClick={onDeleteSubmit}
                    color="primary"
                    variant="contained"
                    noMargin
                    disabled={!accepted}
                >
                    Delete
                </Button>
                <Button
                    onClick={onClose}
                    noMargin
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AcceptCheckbox: React.FC<{ accepted: boolean; setAccepted: React.Dispatch<React.SetStateAction<boolean>> }> = ({ accepted, setAccepted }) => {
    return (
        <Checkbox
            checked={accepted}
            label="I accept and want to proceed"
            onChange={(e, checked) => {
                setAccepted(checked);
            }}
            labelProps={{ className: 'tw-font-medium' }}
        />
    );
};

export { AppleEndpointCommands, AppleEndpointListCommands };
