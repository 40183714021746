import React from 'react';
import { PremiumFeature } from '../contact/contact.components';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

interface SecurityPermissionsCheckProps {
    children: React.ReactNode;
    teaserProps?: object;
}

export const SecurityPermissionsCheck: React.FC<SecurityPermissionsCheckProps> = ({ children, teaserProps = {} }) => {
    const { permissions } = useAuthContext();
    if (permissions.canViewSecurity) {
        return children;
    }
    return <SecurityTeaser {...teaserProps} />;
};

const SecurityTeaser = () => {
    return (
        <div className="tw-h-full tw-p-0.5">
            <WidgetPaper
                headerless
                className="tw-h-full"
            >
                <PremiumFeature
                    description="CapaOne Security automatically detects security vulnerabilities and exposures (CVE) in applications. The findings are presented on a simple dashboard that empowers you to easily identify and update vulnerable applications."
                    icon="capaoneSecurity"
                    defaultSubject="CapaOne Security"
                    productPage="https://www.capasystems.com/capaone-security"
                />
            </WidgetPaper>
        </div>
    );
};
